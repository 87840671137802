import {
  AuditOutlined,
  BarChartOutlined,
  CalendarOutlined,
  CarOutlined,
  CloudServerOutlined,
  DesktopOutlined,
  DollarOutlined,
  GiftOutlined,
  GoldOutlined,
  HomeOutlined,
  LogoutOutlined,
  PictureOutlined,
  SelectOutlined,
  SettingOutlined,
  TeamOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  BarcodeOutlined,
  BuildOutlined,
  SlidersOutlined,
  OneToOneOutlined,
  EuroCircleOutlined,
  AppstoreOutlined,
  NotificationOutlined,
  PhoneOutlined,
  MessageOutlined,
  ScheduleOutlined,
  NodeIndexOutlined,
  LineChartOutlined,
  KeyOutlined,
  MedicineBoxOutlined,
  IssuesCloseOutlined,
  CheckCircleOutlined,
  AccountBookOutlined,
  FlagOutlined,
  LaptopOutlined,
  Loading3QuartersOutlined,
  BankOutlined,
  PercentageOutlined,
  ExperimentOutlined,
} from "@ant-design/icons";
import { Layout, Menu, notification } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  IoApertureOutline,
  IoBoatOutline,
  IoCashOutline,
  IoCog,
  IoPaperPlane,
  IoStar,
  IoStarOutline,
} from "react-icons/io5";
import SubMenu from "antd/es/menu/SubMenu";
import { logoutAdmin } from "../actions/AuthAction";
import { isPermitted } from "../utils/PermissionManager";
import { MdBikeScooter } from "react-icons/md";
//   import { sidebarItems } from "../utils/Sidebars";
const { Sider } = Layout;

export default function SideMenu(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  const roles = useSelector((state) => state.auth.profile?.roles);
  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  const menuStyle = {
    fontSize: "16px",
    width: "18px",
  };

  return (
    <Menu
      theme="light"
      mode="inline"
      defaultSelectedKeys={[path.toString()]}
      defaultOpenKeys={["accounts"]}
    >
      <Menu.Item key={"/"} icon={<HomeOutlined style={menuStyle} />}>
        <Link to={"/"}>Dashboard</Link>
      </Menu.Item>

      <Menu.Item key={"/customers"} icon={<TeamOutlined style={menuStyle} />}>
        <Link to={"/customers"}>Customers</Link>
      </Menu.Item>

      <Menu.Item key={"/orders"} icon={<GoldOutlined style={menuStyle} />}>
        <Link to={"/orders"}>Orders</Link>
      </Menu.Item>
      {isPermitted(roles, ["admin", "bd_purchase", "cn_purchase"]) && (
        <Menu.Item
          key={"/air-purchase"}
          icon={<IoPaperPlane style={menuStyle} />}
        >
          <Link to={"/air-purchase"}>Air Purchase</Link>
        </Menu.Item>
      )}
      {isPermitted(roles, ["admin", "bd_purchase", "cn_purchase"]) && (
        <Menu.Item
          key={"/sea-purchase"}
          icon={<IoBoatOutline style={menuStyle} />}
        >
          <Link to={"/sea-purchase"}>Sea Purchase</Link>
        </Menu.Item>
      )}
      {isPermitted(roles, ["admin", "bd_purchase", "cn_purchase"]) && (
        <Menu.Item
          key={"/incomplete-purchase"}
          icon={<IssuesCloseOutlined style={menuStyle} />}
        >
          <Link to={"/incomplete-purchase"}>Incomplete Purchase</Link>
        </Menu.Item>
      )}
      {isPermitted(roles, ["admin", "bd_purchase", "cn_purchase"]) && (
        <Menu.Item
          key={"/purchase-verification"}
          icon={<CheckCircleOutlined style={menuStyle} />}
        >
          <Link to={"/purchase-verification"}>Purchase Verification</Link>
        </Menu.Item>
      )}
      {isPermitted(roles, ["admin", "bd_purchase", "cn_purchase"]) && (
        <Menu.Item
          key={"/purchase-payment"}
          icon={<AccountBookOutlined style={menuStyle} />}
        >
          <Link to={"/purchase-payment"}>Purchase Payment</Link>
        </Menu.Item>
      )}
      {isPermitted(roles, ["admin", "bd_purchase", "cn_purchase"]) && (
        <Menu.Item
          key={"/tracking"}
          icon={<BarcodeOutlined style={menuStyle} />}
        >
          <Link to={"/tracking"}>Tracking</Link>
        </Menu.Item>
      )}
      {isPermitted(roles, ["admin", "shipment"]) && (
        <Menu.Item key={"/sorting"} icon={<BuildOutlined style={menuStyle} />}>
          <Link to={"/sorting"}>Sorting</Link>
        </Menu.Item>
      )}

      {isPermitted(roles, ["admin", "shipment"]) && (
        <Menu.Item
          key={"/shipments"}
          icon={<AuditOutlined style={menuStyle} />}
        >
          <Link to={"/shipments"}>Shipments</Link>
        </Menu.Item>
      )}
      {isPermitted(roles, ["admin", "shipment"]) && (
        <Menu.Item key={"/qc"} icon={<ExperimentOutlined style={menuStyle} />}>
          <Link to={"/qc"}>QC</Link>
        </Menu.Item>
      )}
      {isPermitted(roles, ["admin", "shipment"]) && (
        <Menu.Item
          key={"/qc-customer"}
          icon={<ScheduleOutlined style={menuStyle} />}
        >
          <Link to={"/qc-customer"}>QC Customer</Link>
        </Menu.Item>
      )}
      {isPermitted(roles, ["admin", "shipment"]) && (
        <Menu.Item
          key={"/multiple-shipments"}
          icon={<AppstoreOutlined style={menuStyle} />}
        >
          <Link to={"/multiple-shipments"}>Multiple Shipments</Link>
        </Menu.Item>
      )}

      {isPermitted(roles, ["admin", "delivery"]) && (
        <Menu.Item key={"/delivery"} icon={<CarOutlined style={menuStyle} />}>
          <Link to={"/delivery"}>Delivery</Link>
        </Menu.Item>
      )}

      {isPermitted(roles, ["admin", "delivery"]) && (
        <Menu.Item
          key={"/courier"}
          icon={<SlidersOutlined style={menuStyle} />}
        >
          <Link to={"/courier"}>Courier</Link>
        </Menu.Item>
      )}
      {isPermitted(roles, ["admin", "delivery"]) && (
        <Menu.Item
          key={"/steadfast-bookings"}
          icon={<SelectOutlined style={menuStyle} />}
        >
          <Link to={"/steadfast-bookings"}>SteadFast Booking</Link>
        </Menu.Item>
      )}

      {isPermitted(roles, ["admin", "delivery"]) && (
        <Menu.Item
          key={"/skyexpress-bookings"}
          icon={<CarOutlined style={menuStyle} />}
        >
          <Link to={"/skyexpress-bookings"}>SkyExpress Booking</Link>
        </Menu.Item>
      )}

      {isPermitted(roles, ["admin", "delivery"]) && (
        <Menu.Item
          key={"/office-delivery"}
          icon={<OneToOneOutlined style={menuStyle} />}
        >
          <Link to={"/office-delivery"}>Office Delivery</Link>
        </Menu.Item>
      )}
      {isPermitted(roles, ["admin", "delivery"]) && (
        <Menu.Item
          key={"/cash-counter"}
          icon={<DollarOutlined style={menuStyle} />}
        >
          <Link to={"/cash-counter"}>Cash Counter</Link>
        </Menu.Item>
      )}
      {isPermitted(roles, ["admin", "crm"]) && (
        <Menu.Item key={"/my-crm"} icon={<DesktopOutlined style={menuStyle} />}>
          <Link to={"/my-crm"}>My CRM</Link>
        </Menu.Item>
      )}
      {isPermitted(roles, ["admin"]) && (
        <SubMenu
          key="crm"
          icon={
            <img
              src={require("../assets/crm.png")}
              style={{ width: 24, marginBottom: 5 }}
              alt=""
            />
          }
          title="CRM"
        >
          {isPermitted(roles, ["admin"]) && (
            <Menu.Item
              key={"/crm/managers"}
              icon={<UserOutlined style={menuStyle} />}
            >
              <Link to={"/crm/managers"}>Managers</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["admin"]) && (
            <Menu.Item
              key={"/crm/target-fullfil"}
              icon={<PercentageOutlined style={menuStyle} />}
            >
              <Link to={"/crm/target-fullfil"}>Target & Fullfil</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["admin"]) && (
            <Menu.Item
              key={"/crm/slab-control"}
              icon={<BarChartOutlined style={menuStyle} />}
            >
              <Link to={"/crm/slab-control"}>Slab Control</Link>
            </Menu.Item>
          )}
        </SubMenu>
      )}

      {isPermitted(roles, ["admin", "customer_care"]) && (
        <Menu.Item key={"/calls"} icon={<PhoneOutlined style={menuStyle} />}>
          <Link to={"/calls"}>Calls</Link>
        </Menu.Item>
      )}

      {isPermitted(roles, ["admin"]) && (
        <Menu.Item key={"/coupons"} icon={<GiftOutlined style={menuStyle} />}>
          <Link to={"/coupons"}>Coupons</Link>
        </Menu.Item>
      )}

      {isPermitted(roles, ["admin"]) && (
        <Menu.Item
          key={"/campaigns"}
          icon={<NotificationOutlined style={menuStyle} />}
        >
          <Link to={"/campaigns"}>Campaigns</Link>
        </Menu.Item>
      )}

      <Menu.Item key={"/support"} icon={<MessageOutlined style={menuStyle} />}>
        <Link to={"/support"}>Support</Link>
      </Menu.Item>

      {/* {isPermitted(roles, ["admin", "accountant"]) && (
        <Menu.Item key={"/payments"} icon={<IoCashOutline style={menuStyle} />}>
          <Link to={"/payments"}>Payments</Link>
        </Menu.Item>
      )} */}
      {isPermitted(roles, ["admin", "accountant"]) && (
        <Menu.Item key={"/point"} icon={<IoStarOutline style={menuStyle} />}>
          <Link to={"/point"}>Point</Link>
        </Menu.Item>
      )}
      {isPermitted(roles, ["admin", "accountant", "delivery"]) && (
        <SubMenu key="refund" icon={<LaptopOutlined />} title="Refund Hub">
          {isPermitted(roles, ["admin", "accountant", "delivery"]) && (
            <Menu.Item
              key={"/pending-refunds"}
              icon={<Loading3QuartersOutlined style={menuStyle} />}
            >
              <Link to={"/pending-refunds"}>Pending Refund</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["admin", "accountant", "delivery"]) && (
            <Menu.Item
              key={"/ready-refunds"}
              icon={<CheckCircleOutlined style={menuStyle} />}
            >
              <Link to={"/ready-refunds"}>Ready to Refund</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["admin", "accountant", "delivery"]) && (
            <Menu.Item
              key={"/refund-history"}
              icon={<NodeIndexOutlined style={menuStyle} />}
            >
              <Link to={"/refund-history"}>Refund History</Link>
            </Menu.Item>
          )}
        </SubMenu>
      )}

      {/* {isPermitted(roles, ["super_admin", "admin"]) && (
        <SubMenu key="reports" icon={<BarChartOutlined />} title="Reports">
          {isPermitted(roles, ["super_admin", "admin"]) && (
            <Menu.Item
              key={"/monthly-report"}
              icon={<CalendarOutlined style={menuStyle} />}
            >
              <Link to={"/monthly-report"}>Monthly Report</Link>
            </Menu.Item>
          )}

          {isPermitted(roles, ["super_admin"]) && (
            <Menu.Item
              key={"/profit-report"}
              icon={<DollarOutlined style={menuStyle} />}
            >
              <Link to={"/profit-report"}>Profit Report</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["super_admin"]) && (
            <Menu.Item
              key={"/bank-report"}
              icon={<BankOutlined style={menuStyle} />}
            >
              <Link to={"/bank-report"}>Bank Report</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["super_admin"]) && (
            <Menu.Item
              key={"/investment-report"}
              icon={<PercentageOutlined style={menuStyle} />}
            >
              <Link to={"/investment-report"}>Investment Report</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["admin", "accountant", "delivery"]) && (
            <Menu.Item
              key={"/delivery-payment-report"}
              icon={<CarOutlined style={menuStyle} />}
            >
              <Link to={"/delivery-payment-report"}>
                Delivery Payment Report
              </Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["super_admin", "admin"]) && (
            <Menu.Item
              key={"/shipment-report"}
              icon={<FlagOutlined style={menuStyle} />}
            >
              <Link to={"/shipment-report"}>Shipment Report</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["admin", "accountant", "delivery"]) && (
            <Menu.Item
              key={"/delivery-due-report"}
              icon={<MdBikeScooter style={menuStyle} />}
            >
              <Link to={"/delivery-due-report"}>Delivery Due Report</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["admin"]) && (
            <Menu.Item
              key={"/employee-purchasing-report"}
              icon={<PictureOutlined style={menuStyle} />}
            >
              <Link to={"/employee-purchasing-report"}>Purchasing Report</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["admin"]) && (
            <Menu.Item
              key={"/employee-purchase-report"}
              icon={<DesktopOutlined style={menuStyle} />}
            >
              <Link to={"/employee-purchase-report"}>Purchased Report</Link>
            </Menu.Item>
          )}

          {isPermitted(roles, ["admin"]) && (
            <Menu.Item
              key={"/purchase-report"}
              icon={<KeyOutlined style={menuStyle} />}
            >
              <Link to={"/purchase-report"}>Purchase Cost Report</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["super_admin", "admin"]) && (
            <Menu.Item
              key={"/refund-report"}
              icon={<LaptopOutlined style={menuStyle} />}
            >
              <Link to={"/refund-report"}>Refund Report</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["admin"]) && (
            <Menu.Item
              key={"/payment-report"}
              icon={<ScheduleOutlined style={menuStyle} />}
            >
              <Link to={"/payment-report"}>Payment Report</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["super_admin", "admin"]) && (
            <Menu.Item
              key={"/steadfast-report"}
              icon={<MedicineBoxOutlined style={menuStyle} />}
            >
              <Link to={"/steadfast-report"}>SteadFast Report</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["admin"]) && (
            <Menu.Item
              key={"/gateway-report"}
              icon={<NodeIndexOutlined style={menuStyle} />}
            >
              <Link to={"/gateway-report"}>Gateway Report</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["admin"]) && (
            <Menu.Item
              key={"/cash-report"}
              icon={<LineChartOutlined style={menuStyle} />}
            >
              <Link to={"/cash-report"}>Cash Report</Link>
            </Menu.Item>
          )}
          {isPermitted(roles, ["admin"]) && (
            <Menu.Item
              key={"/call-report"}
              icon={<PhoneOutlined style={menuStyle} />}
            >
              <Link to={"/call-report"}>Call Report</Link>
            </Menu.Item>
          )}

          {isPermitted(roles, ["admin"]) && (
            <Menu.Item
              key={"/sms-report"}
              icon={<MessageOutlined style={menuStyle} />}
            >
              <Link to={"/sms-report"}>Sms Report</Link>
            </Menu.Item>
          )}
        </SubMenu>
      )} */}

      {isPermitted(roles, ["admin"]) && (
        <SubMenu
          key="agent"
          icon={
            <img
              src={require("../assets/businessman.png")}
              style={{ width: 24, marginBottom: 5 }}
              alt=""
            />
          }
          title="Agentship"
        >
          <Menu.Item
            key={"/agents"}
            icon={
              <img
                src={require("../assets/agents.png")}
                style={{ width: 24 }}
                alt=""
              />
            }
          >
            <Link to={"/agents"}>Agents</Link>
          </Menu.Item>
          <Menu.Item
            key={"/agent/orders"}
            icon={
              <img
                src={require("../assets/orders.png")}
                style={{ width: 24 }}
                alt=""
              />
            }
          >
            <Link to={"/agent/orders"}>Orders</Link>
          </Menu.Item>
          <Menu.Item
            key={"/agent/withdraws"}
            icon={
              <img
                src={require("../assets/cash.png")}
                style={{ width: 24 }}
                alt=""
              />
            }
          >
            <Link to={"/agent/withdraws"}>Withdraws</Link>
          </Menu.Item>
        </SubMenu>
      )}

      {isPermitted(roles, ["super_admin"]) && (
        <SubMenu key="settings" icon={<SettingOutlined />} title="Settings">
          <Menu.Item
            key={"/settings/rates"}
            icon={<EuroCircleOutlined style={menuStyle} />}
          >
            <Link to={"/settings/rates"}>Rates & Margin</Link>
          </Menu.Item>
          <Menu.Item
            key={"/settings/shipping-charges"}
            icon={<CarOutlined style={menuStyle} />}
          >
            <Link to={"/settings/shipping-charges"}>Shipping Charges</Link>
          </Menu.Item>
        </SubMenu>
      )}

      {isPermitted(roles, ["super_admin"]) && (
        <Menu.Item
          key="/admins"
          icon={
            <UsergroupAddOutlined style={{ fontSize: "14px", width: "18px" }} />
          }
        >
          <Link to={"/admins"}>Admins</Link>
        </Menu.Item>
      )}

      <Menu.Item
        key="/SettingOutlined "
        icon={<UserOutlined style={{ fontSize: "14px", width: "18px" }} />}
      >
        <Link to={"/profile"}>Profile</Link>
      </Menu.Item>
      <Menu.Item
        key="/logout"
        onClick={() => {
          dispatch(logoutAdmin(() => {}));
          openNotificationWithIcon(
            "success",
            "Success",
            "Logged out successfully"
          );
        }}
        icon={<LogoutOutlined style={{ fontSize: "16px", width: "18px" }} />}
      >
        Logout
      </Menu.Item>
    </Menu>
  );
}
