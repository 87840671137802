import "./App.css";
import React, { Suspense, lazy, useState } from "react";
import { ConfigProvider } from "antd";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { store } from "./store";

import { Provider } from "react-redux";
import setAuthToken from "./utils/setAuthToken";
import AdminRoute from "./routes/AdminRoute";
import Sidebar from "./layout/Sidebar";
import Navbar from "./layout/Navbar";
import { saveConfig, setAdmin } from "./actions/AuthAction";
import { setConfig } from "./reducers/configReducer";

import { isPermitted } from "./utils/PermissionManager";
import { setProfile } from "./reducers/authReducer";
import Bottombar from "./layout/BottomBar";

const Purchase = lazy(() => import("./pages/Purchase"));
const SeaPurchasing = lazy(() => import("./pages/SeaPurchasing"));
const SlabControl = lazy(() => import("./pages/crm/SlabControl"));
const QcCustomer = lazy(() => import("./pages/QcCustomer"));
const SkyExpressBookings = lazy(() => import("./pages/SkyExpressBookings"));
const BalanceHub = lazy(() => import("./pages/BalanceHub"));
const CRMCustomer = lazy(() => import("./pages/crm/CRMCustomer"));
const CrmManagers = lazy(() => import("./pages/crm/CrmManagers"));
const MyCrm = lazy(() => import("./pages/crm/MyCrm"));
const CrmTargetFullfil = lazy(() => import("./pages/crm/CrmTargetFullfil"));

const QcAcknowledgement = lazy(() => import("./pages/QcAcknowledgement"));

const RefundHistory = lazy(() => import("./pages/RefundHistory"));

const ReadyRefunds = lazy(() => import("./pages/ReadyRefund"));

const RefundHub = lazy(() => import("./pages/RefundHub"));

const PurchaseVerification = lazy(() => import("./pages/PurchaseVerification"));
const PurchasePayment = lazy(() => import("./pages/PurchasePayment"));

const IncompletePurchase = lazy(() => import("./pages/IncompletePurchase"));
const SteadFastBooking = lazy(() => import("./pages/SteadFastBooking"));
const Agents = lazy(() => import("./pages/Agents"));
const AgentOrders = lazy(() => import("./pages/AgentOrders"));
const AgentWithdraw = lazy(() => import("./pages/AgentWithdraws"));
const AgentWithdrawPay = lazy(() => import("./pages/AgentWithdrawPay"));

const Campaign = lazy(() => import("./pages/Campaign"));
const ManageTicket = lazy(() => import("./pages/ManageTicket"));

const Calls = lazy(() => import("./pages/Calls"));
const Support = lazy(() => import("./pages/Support"));

const Campaigns = lazy(() => import("./pages/Campaigns"));
const CartonOrders = lazy(() => import("./pages/CartonOrders"));
const RateSettings = lazy(() => import("./pages/RateSettings"));
const ChargeSetting = lazy(() => import("./pages/ChargeSettings"));
const BecommerceSetting = lazy(() => import("./pages/BecommerceSetting"));
const MultipleShipments = lazy(() => import("./pages/MultipleShipments"));

const Invoice = lazy(() => import("./pages/Invoice"));
const CashCounter = lazy(() => import("./pages/CashCounter"));

const CourierHub = lazy(() => import("./pages/CourierHub"));
const OfficeDelivery = lazy(() => import("./pages/OfficeDelivery"));

const Coupon = lazy(() => import("./pages/Coupons"));
const DeliveryComplete = lazy(() => import("./pages/DeliveryComplete"));
const Delivery = lazy(() => import("./pages/Delivery"));

const Tracking = lazy(() => import("./pages/Tracking"));
const Shipments = lazy(() => import("./pages/Shipments"));
const ShipmentDetails = lazy(() => import("./pages/ShipmentDetails"));
const Sorting = lazy(() => import("./pages/Sorting"));

const Customers = lazy(() => import("./pages/Customers"));
const Login = lazy(() => import("./pages/Login"));
const Orders = lazy(() => import("./pages/Orders"));
const ManageOrder = lazy(() => import("./pages/ManageOrder"));
const Admins = lazy(() => import("./pages/Admins"));
const Payments = lazy(() => import("./pages/Payments"));
const Profile = lazy(() => import("./pages/Profile"));
const ComingSoon = lazy(() => import("./pages/ComingSoon"));
const Purchasing = lazy(() => import("./pages/Purchasing"));

if (localStorage.config) {
  let config = JSON.parse(localStorage.config);
  let profile = config.profile;
  store.dispatch(setProfile(profile));
  store.dispatch(setConfig(config));
}

if (localStorage.skyadmin) {
  setAuthToken(localStorage.skyadmin);
  store.dispatch(setAdmin(true));
  store.dispatch(saveConfig());
}

const DashboardNew = lazy(() => import("./pages/DashboardNew"));

function App() {
  const client = window.screen.width > 768 ? "web" : "app";
  const [show, setshow] = useState(client === "web" ? false : true);
  const [trigger, settrigger] = useState(false);

  const id = store.getState().auth?.profile?._id;

  const host = window.location.host;

  if (host !== "shahil.skybuybd.com" && host !== "localhost:3000") {
    return (
      <div
        className="flex align-center justify-center flexColumn"
        style={{ height: "100vh", width: "100%", background: "#fff" }}
      >
        <img src={require("./assets/locked.png")} style={{ width: 220 }} />
        <h2 className="mt1">Site Locked</h2>
        <p>This website is locked please contact authorized person.</p>
      </div>
    );
  }

  const roles = store.getState().auth?.profile?.roles
    ? store.getState().auth?.profile?.roles
    : [];

  return (
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#167389",
          },
        }}
      >
        <BrowserRouter>
          <Suspense fallback={<div className="pageProgress"></div>}>
            <div className="App">
              <Sidebar state={{ show, setshow }} />
              <div>
                <Navbar state={{ settrigger, setshow, show }} />
                {roles?.length > 0 && <Bottombar />}
                <Routes>
                  <Route
                    exact
                    path="/"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<DashboardNew />}
                      />
                    }
                  />

                  <Route
                    exact
                    path="/customers"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Customers />}
                      />
                    }
                  />

                  <Route
                    exact
                    path="/orders"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Orders />}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/order/:id"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<ManageOrder />}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/purchase/:id"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Purchase />}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/campaign/:id"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Campaign />}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/cartons/:id"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<CartonOrders />}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/support/:id"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<ManageTicket />}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/calls"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Calls />}
                      />
                    }
                  />
                  {isPermitted(roles, ["admin", "shipment"]) && (
                    <Route
                      exact
                      path="/shipment/:id"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<ShipmentDetails />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "delivery"]) && (
                    <Route
                      exact
                      path="/steadfast-bookings"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<SteadFastBooking />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "delivery"]) && (
                    <Route
                      exact
                      path="/skyexpress-bookings"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<SkyExpressBookings />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin"]) && (
                    <Route
                      exact
                      path="/agents"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Agents />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin"]) && (
                    <Route
                      exact
                      path="/agent/orders"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<AgentOrders />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin"]) && (
                    <Route
                      exact
                      path="/agent/withdraws"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<AgentWithdraw />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin"]) && (
                    <Route
                      exact
                      path="/agent/withdraw/:id"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<AgentWithdrawPay />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "delivery"]) && (
                    <Route
                      exact
                      path="/courier"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<CourierHub />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "delivery"]) && (
                    <Route
                      exact
                      path="/invoice/:id"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Invoice />}
                        />
                      }
                    />
                  )}

                  {isPermitted(roles, [
                    "admin",
                    "bd_purchase",
                    "cn_purchase",
                  ]) && (
                    <Route
                      exact
                      path="/qc"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<QcAcknowledgement />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, [
                    "admin",
                    "bd_purchase",
                    "cn_purchase",
                  ]) && (
                    <Route
                      exact
                      path="/qc-customer"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<QcCustomer />}
                        />
                      }
                    />
                  )}

                  {isPermitted(roles, ["super_admin"]) && (
                    <Route
                      exact
                      path="/settings/rates"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<RateSettings />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["super_admin"]) && (
                    <Route
                      exact
                      path="/settings/shipping-charges"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<ChargeSetting />}
                        />
                      }
                    />
                  )}
                  {[
                    "646b312b4bf0ab131ff44e9a",
                    "644904810578558b71b8e622",
                  ].includes(id) &&
                    isPermitted(roles, ["admin"]) && (
                      <Route
                        exact
                        path="/settings/becommerce"
                        element={
                          <AdminRoute
                            state={{ show, trigger }}
                            component={<BecommerceSetting />}
                          />
                        }
                      />
                    )}

                  {isPermitted(roles, ["admin", "delivery"]) && (
                    <Route
                      exact
                      path="/cash-counter/:id?"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<CashCounter />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "accountant"]) && (
                    <Route
                      exact
                      path="/point"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<BalanceHub />}
                        />
                      }
                    />
                  )}
                  {/* {isPermitted(roles, ["admin"]) && (
                    <Route
                      exact
                      path="/shipment-report/:id?"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<ShipmentInvoiceReport />}
                        />
                      }
                    />
                  )} */}
                  {/* {isPermitted(roles, ["admin"]) && (
                    <Route
                      exact
                      path="/delivery-due-report"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<DeliveryDueReport />}
                        />
                      }
                    />
                  )} */}
                  {isPermitted(roles, ["admin", "delivery"]) && (
                    <Route
                      exact
                      path="/office-delivery"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<OfficeDelivery />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin"]) && (
                    <Route
                      exact
                      path="/crm/managers"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<CrmManagers />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin"]) && (
                    <Route
                      exact
                      path="/crm/target-fullfil"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<CrmTargetFullfil />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin"]) && (
                    <Route
                      exact
                      path="/crm/slab-control"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<SlabControl />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "crm"]) && (
                    <Route
                      exact
                      path="/my-crm/:id?"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<MyCrm />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "crm"]) && (
                    <Route
                      exact
                      path="/crm/customer/:id"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<CRMCustomer />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "shipment"]) && (
                    <Route
                      exact
                      path="/shipments"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Shipments />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "shipment"]) && (
                    <Route
                      exact
                      path="/multiple-shipments"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<MultipleShipments />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "delivery"]) && (
                    <Route
                      exact
                      path="/delivery"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Delivery />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "delivery"]) && (
                    <Route
                      exact
                      path="/delivery/:id"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<DeliveryComplete />}
                        />
                      }
                    />
                  )}
                  {/* {isPermitted(roles, ["super_admin", "admin"]) && (
                    <Route
                      exact
                      path="/monthly-report"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<MonthlyReport />}
                        />
                      }
                    />
                  )} */}
                  {/* {isPermitted(roles, ["super_admin", "admin"]) && (
                    <Route
                      exact
                      path="/bank-report"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<BankReport />}
                        />
                      }
                    />
                  )} */}
                  {/* {isPermitted(roles, ["super_admin", "admin"]) && (
                    <Route
                      exact
                      path="/investment-report"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<InvestmentReport />}
                        />
                      }
                    />
                  )} */}
                  {/* {isPermitted(roles, ["super_admin", "admin"]) && (
                    <Route
                      exact
                      path="/employee-purchase-report"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<EmployeePurchaseReport />}
                        />
                      }
                    />
                  )} */}
                  {/* {isPermitted(roles, ["super_admin", "admin"]) && (
                    <Route
                      exact
                      path="/employee-purchasing-report"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<EmployeePurchasingReport />}
                        />
                      }
                    />
                  )} */}
                  {/* {isPermitted(roles, ["super_admin", "admin"]) && (
                    <Route
                      exact
                      path="/steadfast-report"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<SteadFastReport />}
                        />
                      }
                    />
                  )} */}
                  {/* {isPermitted(roles, [
                    "super_admin",
                    "admin",
                    "accountant",
                    "delivery",
                  ]) && (
                    <Route
                      exact
                      path="/delivery-payment-report"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<DeliveryPaymentReport />}
                        />
                      }
                    />
                  )} */}
                  {isPermitted(roles, [
                    "super_admin",
                    "admin",
                    "accountant",
                    "delivery",
                  ]) && (
                    <Route
                      exact
                      path="/pending-refunds"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<RefundHub />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, [
                    "super_admin",
                    "admin",
                    "accountant",
                    "delivery",
                  ]) && (
                    <Route
                      exact
                      path="/refund-history"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<RefundHistory />}
                        />
                      }
                    />
                  )}
                  {/* {isPermitted(roles, ["super_admin", "admin"]) && (
                    <Route
                      exact
                      path="/refund-report"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<RefundReport />}
                        />
                      }
                    />
                  )} */}
                  {isPermitted(roles, [
                    "super_admin",
                    "admin",
                    "accountant",
                    "delivery",
                  ]) && (
                    <Route
                      exact
                      path="/ready-refunds"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<ReadyRefunds />}
                        />
                      }
                    />
                  )}

                  <Route
                    exact
                    path="/support"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Support />}
                      />
                    }
                  />

                  {/* {isPermitted(roles, ["super_admin"]) && (
                    <Route
                      exact
                      path="/profit-report"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<ProfitReport />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin"]) && (
                    <Route
                      exact
                      path="/gateway-report"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<GateWayReport />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin"]) && (
                    <Route
                      exact
                      path="/purchase-report"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<PurchaseReport />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin"]) && (
                    <Route
                      exact
                      path="/payment-report"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<PaymentReport />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin"]) && (
                    <Route
                      exact
                      path="/sms-report"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<SmsReport />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin"]) && (
                    <Route
                      exact
                      path="/cash-report"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<CashReport />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin"]) && (
                    <Route
                      exact
                      path="/call-report"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<CallReport />}
                        />
                      }
                    />
                  )} */}
                  {isPermitted(roles, ["admin"]) && (
                    <Route
                      exact
                      path="/coupons"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Coupon />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin"]) && (
                    <Route
                      exact
                      path="/campaigns"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Campaigns />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "shipment"]) && (
                    <Route
                      exact
                      path="/sorting"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Sorting />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["super_admin"]) && (
                    <Route
                      exact
                      path="/admins"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Admins />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, [
                    "admin",
                    "bd_purchase",
                    "cn_purchase",
                  ]) && (
                    <Route
                      exact
                      path="/air-purchase"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Purchasing />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, [
                    "admin",
                    "bd_purchase",
                    "cn_purchase",
                  ]) && (
                    <Route
                      exact
                      path="/sea-purchase"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<SeaPurchasing />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, [
                    "admin",
                    "bd_purchase",
                    "cn_purchase",
                  ]) && (
                    <Route
                      exact
                      path="/incomplete-purchase"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<IncompletePurchase />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, [
                    "admin",
                    "bd_purchase",
                    "cn_purchase",
                  ]) && (
                    <Route
                      exact
                      path="/purchase-verification"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<PurchaseVerification />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, [
                    "admin",
                    "bd_purchase",
                    "cn_purchase",
                  ]) && (
                    <Route
                      exact
                      path="/purchase-payment"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<PurchasePayment />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, [
                    "admin",
                    "bd_purchase",
                    "cn_purchase",
                  ]) && (
                    <Route
                      exact
                      path="/tracking"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Tracking />}
                        />
                      }
                    />
                  )}
                  {/* {isPermitted(roles, ["admin", "accountant"]) && (
                    <Route
                      exact
                      path="/payments"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Payments />}
                        />
                      }
                    />
                  )} */}

                  <Route
                    exact
                    path="/profile"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Profile />}
                      />
                    }
                  />

                  <Route exact path="/login" element={<Login />} />
                  <Route
                    path="*"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={
                          <div
                            style={{
                              height: "calc(100vh - 78px)",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "#fff",
                            }}
                          >
                            <ComingSoon />
                          </div>
                        }
                      />
                    }
                  />
                </Routes>
              </div>
            </div>
          </Suspense>
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
